<!--
  PACKAGE_NAME : src/pages/euc/chineseWall/history
  FILE_NAME : index.vue
  AUTHOR : jhcho
  DATE : 2024-06-11
  DESCRIPTION :
-->
<template>
  <div class="page-sub-box">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <Search
        codeKey="null"
        :customSearchList="searchType.list"
        :searchObj="searchType.obj"
        @change-search-type="$_changeSearchType"
        @onSearchClick="searchData($event)"
      >
        <template v-slot:before>
          <div class="ui-datepicker-item">
            <DxDateBox
              :edit-enabled="false"
              :styling-mode="stylingMode"
              :width="120"
              v-model="searchType.customTypes.dayStart"
              type="date"
              display-format="yyyy.MM.dd"
              @value-changed="onChangeSearchDay"
              :dateOutOfRangeMessage="
                $_msgContents('COMMON.MESSAGE.SEARCH_DATE_RANGE_ERROR', { defaultValue: '종료일은 시작일보다 크거나 같아야 합니다.' })
              "
            >
            </DxDateBox>
          </div>
          <div class="ui-datepicker period">
            <span class="dash">~</span>
          </div>
          <div class="ui-datepicker-item">
            <DxDateBox
              :styling-mode="stylingMode"
              :width="120"
              v-model="searchType.customTypes.dayEnd"
              type="date"
              display-format="yyyy.MM.dd"
              @value-changed="onChangeSearchDay"
              :dateOutOfRangeMessage="
                $_msgContents('COMMON.MESSAGE.SEARCH_DATE_RANGE_ERROR', { defaultValue: '종료일은 시작일보다 크거나 같아야 합니다.' })
              "
            >
            </DxDateBox>
          </div>
        </template>
      </Search>
    </div>
    <esp-dx-data-grid :data-grid="dataGrid" ref="chineseWallHistoryGrid" />
  </div>
</template>
<script>
  import Search from '@/components/common/search.vue';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { getPastFromToday, isEmpty, isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      Search,
      DxDateBox,
    },
    data() {
      return {
        stylingMode: 'outlined',
        searchType: {
          list: [
            {
              codeNm: this.$_msgContents('UC.WORD.SENDER_NUMBER', { defaultValue: '발신자 번호' }),
              codeValue: 'callingNum',
            },
            {
              codeNm: `${this.$_msgContents('UC.WORD.SENDER', { defaultValue: '발신자' })} ${this.$_msgContents('UC.WORD.USER_NO', {
                defaultValue: '사번',
              })}`,
              codeValue: 'callingUserNo',
            },
            {
              codeNm: `${this.$_msgContents('UC.WORD.SENDER', { defaultValue: '발신자' })} ${this.$_msgContents('COMPONENTS.NAME', {
                defaultValue: '이름',
              })}`,
              codeValue: 'callingUserNm',
            },
            {
              codeNm: this.$_msgContents('UC.WORD.SENDER_DEPT', { defaultValue: '발신부서' }),
              codeValue: 'callingDeptNm',
            },
            {
              codeNm: this.$_msgContents('UC.WORD.RECEIVER_NUMBER', { defaultValue: '수신자 번호' }),
              codeValue: 'calledNum',
            },
            {
              codeNm: `${this.$_msgContents('UC.WORD.RECEIVER', { defaultValue: '수신자' })} ${this.$_msgContents('UC.WORD.USER_NO', {
                defaultValue: '사번',
              })}`,
              codeValue: 'calledUserNo',
            },
            {
              codeNm: `${this.$_msgContents('UC.WORD.RECEIVER', { defaultValue: '수신자' })} ${this.$_msgContents('COMPONENTS.NAME', {
                defaultValue: '이름',
              })}`,
              codeValue: 'calledUserNm',
            },
            {
              codeNm: this.$_msgContents('UC.WORD.RECEIVER_DEPT', { defaultValue: '수신부서' }),
              codeValue: 'calledDeptNm',
            },
            {
              codeNm: this.$_msgContents('UC.WORD.TRANSFORM_NUMBER', { defaultValue: '전환번호' }),
              codeValue: 'dndortransno',
            },
            {
              codeNm: this.$_msgContents('COMPONENTS.TITLE', { defaultValue: '제목' }),
              codeValue: 'title',
            },
          ],
          obj: {
            searchTexts: {
              width: 300,
            },
          },
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: this.$_msgContents('COMPONENTS.ALL', { defaultValue: '전체' }) },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(30, 'days'),
            dayEnd: getPastFromToday(0, 'days'),
            startDt: null,
            endDt: null,
            regDt: null,
          },
          paramsData: null,
        },
        dataGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'chineseWallHistoryGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 225px)', // 주석처리시 100%
          apiActionNm: {},
          excel: {
            title: '차이니즈월 이력',
          },
          showActionButtons: {
            excel: true,
          },
          customEvent: {
            initNewRow: true,
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              multiHeaderNm: '발신자',
              i18n: 'UC.WORD.SENDER',
              columns: [
                {
                  caption: '전화번호',
                  i18n: 'COMPONENTS.PHONE_NUMBER',
                  dataField: 'callingNum',
                  width: 105,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  sortOrder: 'none',
                },
                {
                  caption: '사번',
                  i18n: 'UC.WORD.USER_NO',
                  dataField: 'callingUserNo',
                  width: 90,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  sortOrder: 'none',
                },
                {
                  caption: '이름',
                  i18n: 'COMPONENTS.NAME',
                  dataField: 'callingUserNm',
                  width: 90,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  sortOrder: 'none',
                },
                {
                  caption: '부서',
                  i18n: 'COMPONENTS.DEPT',
                  dataField: 'callingDeptNm',
                  width: 130,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  sortOrder: 'none',
                },
              ],
            },
            {
              multiHeaderNm: '수신자',
              i18n: 'UC.WORD.RECEIVER',
              columns: [
                {
                  caption: '전화번호',
                  i18n: 'COMPONENTS.PHONE_NUMBER',
                  dataField: 'calledNum',
                  width: 105,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  sortOrder: 'none',
                },
                {
                  caption: '사번',
                  i18n: 'UC.WORD.USER_NO',
                  dataField: 'calledUserNo',
                  width: 90,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  sortOrder: 'none',
                },
                {
                  caption: '이름',
                  i18n: 'COMPONENTS.NAME',
                  dataField: 'calledUserNm',
                  width: 90,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  sortOrder: 'none',
                },
                {
                  caption: '부서',
                  i18n: 'COMPONENTS.DEPT',
                  dataField: 'calledDeptNm',
                  width: 130,
                  height: 40,
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  sortOrder: 'none',
                },
              ],
            },
            {
              caption: '전환번호',
              i18n: 'UC.WORD.TRANSFORM_NUMBER',
              dataField: 'dndortransno',
              width: 105,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '제목',
              i18n: 'COMPONENTS.TITLE',
              dataField: 'title',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '등록일',
              i18n: 'COMPONENTS.REGISTRATION_DATE',
              dataField: 'regDt',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },
    methods: {
      onChangeSearchDay() {
        const dayStart = this.$_commonlib.moment(this.searchType.customTypes.dayStart);
        const dayEnd = this.$_commonlib.moment(this.searchType.customTypes.dayEnd);
        const regDt = `${dayStart.format('YYYYMMDD000000')}~${dayEnd.format('YYYYMMDD235959')}`;
        this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      searchData(paramsData) {
        //검색 버튼 클릭시 이벤트
        if (paramsData) {
          this.searchType.paramsData = { ...this.searchType.paramsData, ...paramsData };
        } else {
          //검색 키워드의 값이 없으면
          //검색키는 설정하고, 검색어를 입력하지 않으면, 전체 목록 출력
          let codeValues = this.searchType.list.map(d => d.codeValue);
          if (!isEmpty(this.searchType.paramsData)) {
            Object.keys(this.searchType.paramsData).forEach(key => {
              if (codeValues.includes(key)) {
                delete this.searchType.paramsData[key]; //검색 조건 키 삭제
              }
            });
          }
        }
        this.selectDataList();
      },
      async selectDataList(sort = '+regDt') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData, curriType: 'CHINESEWALL' };

            const payload = {
              actionname: 'EUC_CURRIHISTORY_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.regDt) {
          return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
    },
    mounted() {
      this.selectDataList();
    },
    created() {
      this.onChangeSearchDay();
    },
  };
</script>
<style scoped></style>